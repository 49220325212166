import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getLoggedInUser } from "../state/user";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.isUserLoggedIn);

  useEffect(() => {
    dispatch(getLoggedInUser());
  }, [dispatch]);

  useEffect(() => {
    // If user is not logged in, redirect to the home page.
    if (isLoggedIn === false)
      navigate(`/app/login`, {
        state: {
          from: rest.path,
        },
      });
  }, [isLoggedIn, rest.path]);

  if (!isLoggedIn) {
    // TODO: Create a loading component
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
