import loadable from "@loadable/component";
import { Router } from "@reach/router";
import React from "react";

// import Loading from "../components/Loading";
import PrivateRoute from "../components/PrivateRoute";

// const loadRoute = (component) => {
//   return loadable(component, { fallback: <Loading /> });
// };

const Cart = loadable(() => import("../routes/checkout/Cart"));
const Checkout = loadable(() => import("../routes/checkout/Checkout"));
const CheckoutSuccess = loadable(() =>
  import("../routes/checkout/CheckoutSuccess")
);
const WetMarketCheckout = loadable(() =>
  import("../routes/checkout/wet-market/Checkout")
);
const WetMarketSuccess = loadable(() =>
  import("../routes/checkout/wet-market/WetMarketSuccess")
);
const AddressManager = loadable(() =>
  import("../routes/profile/address/AddressManager")
);
const UserProfile = loadable(() => import("../routes/profile/UserProfile"));
const WalletManager = loadable(() =>
  import("../routes/profile/wallet/WalletManager")
);
const Saved = loadable(() => import("../routes/Saved"));
const MwmOrders = loadable(() => import("../routes/Shipping/MwmOrders"));
// import Shipping from "../routes/Shipping";
const ConsolidationOrderDetail = loadable(() =>
  import("../routes/Shipping/order-detail/ConsolidationOrderDetail")
);
const OrderDetail = loadable(() =>
  import("../routes/Shipping/order-detail/OrderDetail")
);
const UserLogin = loadable(() => import("../routes/UserLogin"));
const UserSignup = loadable(() => import("../routes/UserSignup"));
const Search = loadable(() => import("../routes/Search"));

const Route = ({ children }) => <div>{children}</div>;

const App = () => {
  return (
    <Router basepath="/app">
      <PrivateRoute path="/addressmanager" component={AddressManager} />
      <PrivateRoute path="/profile" component={UserProfile} />
      <PrivateRoute path="/walletmanager" component={WalletManager} />
      <PrivateRoute path="/checkout" component={Checkout} />
      <PrivateRoute path="/wet-market/checkout" component={WetMarketCheckout} />
      <PrivateRoute path="/checkout/success" component={CheckoutSuccess} />
      <PrivateRoute
        path="/wet-market/checkout/success"
        component={WetMarketSuccess}
      />
      {/* <PrivateRoute path="/shipping/*" component={Shipping} /> */}
      <PrivateRoute path="/orders/" component={MwmOrders} />
      <PrivateRoute path="/orders/:id" component={OrderDetail} />
      <PrivateRoute
        path="/consolidationOrders/:id"
        component={ConsolidationOrderDetail}
      />
      <Cart path="/cart" />
      <Saved path="/saved" />
      <UserLogin path="/login" />
      <UserSignup path="/signup" />
      <Route path="/search">
        <Search path="*" />
      </Route>
    </Router>
  );
};

export default App;
